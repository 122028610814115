import './App.css';
import {
  useState,
  useEffect,
  useRef,
  useReducer
} from 'react';

import mqtt from 'mqtt';
import axios from 'axios';
import crypto from 'crypto';

import QuillComponent from './components/quill';
import FileDropzone from './components/dropzone';
import WindowEvents from './components/windowevents';
import AddedDocumentComponent from './components/messenger/document';
import MessageLineComponent from './components/messenger/messageline';

const activeReducer = (prevState, action) => {

  switch (action.type) {
    case 'add':
      const isDuplicate = prevState.some(item => item.id === action.payload.id);
      if (isDuplicate) {
        return prevState;
      }
      return [...prevState, action.payload];
    case 'remove':
      return prevState.filter(item => item.id !== action.payload.id);
    case 'clear':
      return prevState = [];
    default:
      return prevState;
  }
}

function App() {

  const urlMQTT = 'https://nxgbhxayrpgzfjl6fom2ghhjye0dariu.lambda-url.eu-west-2.on.aws/';
  const urlAPI = 'https://rieo3ortuj3nwmw4vibr3eswca0dceug.lambda-url.eu-west-2.on.aws/';
  const urlUser = "https://n3cafclh23jjgowl3j2psgohjm0tkhug.lambda-url.eu-west-2.on.aws/"; // debug only

  const [endPoints, setEndPoints] = useState();
  const [reconnectCount, setReconnectCount] = useState(0);
  const [login, setLogin] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [username, setUsername] = useState('');
  const [myId, setMyId] = useState('');
  const [loginError, setLoginError] = useState(null);

  const [channelList, setChannelList] = useState([]);
  const [createNewChannel, setCreateNewChannel] = useState(false);

  const [channelHistoryPositions, setChannelHistoryPositions] = useState([]);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(false);

  const [timeDiff, setTimeDiff] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [processPacket, setProcessPacket] = useState(false);
  const [messages, setMessages] = useState({});
  const [currentMessages, setCurrentMessages] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [attachedDocuments, setAttachedDocuments] = useState({ document: [] });
  const [lastViewedChannelAt, setLastViewedChannelAt] = useState(null);
  const [newFrom, setNewFrom] = useState(null);

  const [iamActive, setIamActive] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(0);
  const [lastActiveTimerId, setLastActiveTimerId] = useState(null);

  const [deleteFile, setDeleteFile] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deletingMessage, setDeletingMessage] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [editMessageDocs, setEditMessageDocs] = useState([]);

  const [userSettings, setUserSettings] = useState({});
  const [audioEnabled, setAudioEnabled] = useState(true);

  const [mqttClient, setMQTTClient] = useState(null);
  const [mqttPublish, setMQTTPublish] = useState(null);
  const [topicClient, setTopicClient] = useState('');
  const [getHistory, setGetHistory] = useState(null);

  const [panelOpen, setPanelOpen] = useState(null);
  const [noScroll, setNoScroll] = useState(false);
  const [noScrollAction, setNoScrollAction] = useState(false);

  const [rvAppData, setRvAppData] = useState({});

  const [activeUsers, dispatchUsers] = useReducer(activeReducer, []);
  const [usersOnline, dispatchOnline] = useReducer(activeReducer, []);
  const [selectedUser, setSelectedUser] = useState(null);


  const fileList = useRef([]);
  const currentMessageElements = useRef([]);
  const channelName = useRef([]);
  const channelPanel = useRef(null);
  const usersOnlinePanel = useRef(null);
  const userListRefs = useRef([]);
  const friendsName = useRef([]);
  const dropzoneElement = useRef(null);
  const newChannelName = useRef();
  const loginButton = useRef(null);
  const messageInput = useRef(null);
  const appMessages = useRef(null);
  const usernameInput = useRef(null);
  const quillRef = useRef(null);
  const editIndex = useRef(null);
  const switchingChannel = useRef(null);
  const lastScrollPosition = useRef(0);
  const activeTopics = useRef([]);
  const activeUsersMessage = useRef(null);
  const userToken = useRef(null);
  const appMessagesRef = useRef([]);

  useEffect(() => {
    setRvAppData(window.rvAppData);
    console.log('rvAppData', window.rvAppData);
  },
    []);


  useEffect(() => {
    if (lastViewedChannelAt === null || channelList.length === 0)
      return;

    const message = lastViewedChannelAt;
    const topic = message.topic !== undefined ? message.topic.replace('chat/', '') : topicClient.id;

    var currentChannel, currentChannelIndex;
    var updatedChannelList = channelList.filter((channel, index) => {
      if (channel.id === topic) {
        currentChannel = channel;
        currentChannelIndex = index;
      }
      return channel.id !== topic;
    });

    if (currentChannel === undefined) {
      return;
    }

    currentChannel['viewed'] = message.timestamp;
    currentChannel['unread'] = false;
    updatedChannelList.splice(currentChannelIndex, 0, currentChannel);

    updatedChannelList = updatedChannelList.map(channel => {
      channel.id = channel.id.replace('chat/', '');
      return channel;
    });

    setChannelList(updatedChannelList);
    const newSettings = { ...userSettings, ...{ topics: updatedChannelList } }
    setUserSettings(newSettings);

    setMQTTPublish([
      {
        topic: 'user/' + myId,
        message: newSettings
      }
    ]);
    setLastViewedChannelAt(null);

  },
    [lastViewedChannelAt]);


  useEffect(() => {
    if (isLoggedIn) {
      var timeNow = Date.now().toString();
      var timerMark = parseFloat(timeNow.slice(timeNow.length - 4, timeNow.length));

      if (iamActive > 0 && timerMark - lastActiveTime > 100) {
        if (lastActiveTime === 0) {
          setMQTTPublish([
            {
              topic: "chat/" + topicClient.id + "/act",
              message: {
                id: myId,
                alias: userSettings.username, message: { activity: 'active' }
              }
            }
          ]);
        }
        setLastActiveTime(parseFloat(timerMark));
        clearTimeout(lastActiveTimerId);
        setLastActiveTimerId(
          setTimeout(
            () => {
              setMQTTPublish([
                {
                  topic: "chat/" + topicClient.id + "/act",
                  message: {
                    id: myId,
                    alias: userSettings.username,
                    message: { activity: 'inactive' }
                  }
                }
              ]);
              setLastActiveTime(0);
              setIamActive(0);
            }
            , 400)
        );
        setLastActiveTime(timerMark);
      }
    }
  },
    [iamActive]);


  useEffect(() => {
    if (mqttPublish === null || mqttClient === null)
      return;

    mqttPublish.forEach(item => {
      if (item.topic === 'user/' + myId) {
        const key = crypto
          .createHash('sha512')
          .update(myId)
          .digest('hex')
          .substring(0, 32)
        const encryptionIV = crypto
          .createHash('sha512')
          .update(userToken.current)
          .digest('hex')
          .substring(0, 16)

        const cipher = crypto.createCipheriv('aes-256-cbc', key, encryptionIV);
        var encrypted = cipher.update(JSON.stringify(item.message), 'utf8', 'base64');
        encrypted += cipher.final('base64');
        mqttClient.publish(item.topic, JSON.stringify({ id: myId, data: encrypted }));
      } else {
        mqttClient.publish(item.topic, JSON.stringify(item.message));
      }
    });
    setMQTTPublish(null);
  },
    [mqttPublish]);


  useEffect(() => {
    if (!processPacket)
      return;

    var id = myId;
    appMessagesRef.current.forEach(message => {

      var msg = JSON.parse(message.message.toString());
      var topic = message.topic;
      if (topic === 'last-will' || topic === 'client-disconnected') {
        dispatchOnline({ type: 'remove', payload: { id: msg.id, alias: msg.alias } });
      } else if (topic === 'client-connected' && msg.id !== id) {
        setMQTTPublish([
          {
            topic: 'direct/' + msg.id,
            message: { id: id, alias: userSettings.username }
          }
        ]);
        dispatchOnline({ type: 'add', payload: { id: msg.id, alias: msg.alias } });
      } else if (topic === 'direct/' + id) {
        dispatchOnline({ type: 'add', payload: { id: msg.id, alias: msg.alias } });
      } else if (activeTopics.current.includes("chat/" + topic.replace('chat/', ''))) {
        if ('message' in msg) {
          if ('activity' in msg.message) {
            if (msg.id !== myId && topic === topicClient.id + "/act") {
              dispatchUsers({ type: msg.message.activity === 'active' ? 'add' : 'remove', payload: { id: msg.id, alias: msg.alias } });
            }
          } else {
            setNewMessage(msg);
          }
        } else {
          if (topic.replace('chat/', '') === topicClient.id) {
            currentMessageElements.current.forEach((element, index) => {
              if (element === null) {
                return;
              }
              if (parseFloat(element.getAttribute('data-timestamp')) === parseFloat(msg.timestamp)) {
                currentMessageElements.current[index].classList.add('deleted');
              }
            });
          } else {
            var currentMessagesVar = messages[topic.replace(/\//g, "")],
              newMessages = [];
            if (currentMessagesVar !== undefined) {
              newMessages = currentMessagesVar.filter(message => message.timestamp !== msg.timestamp);
            }
            var updatedMessages = messages;
            updatedMessages[topic.replace(/\//g, "")] = newMessages;
            setMessages({ ...updatedMessages });
          }
        }
      }
    });

    appMessagesRef.current = null;
    setProcessPacket(false);
  },
    [processPacket]);


  useEffect(() => {
    function setMQTTsubscribe() {
      if (mqttClient === null) {
        return;
      }
      var defaultTopics = [
        { 'id': 'client-connected', 'name': 'client-connected' },
        { 'id': 'client-disconnected', 'name': 'client-disconnected' },
        { 'id': 'direct/' + myId, 'name': 'direct-messages' },
        { 'id': 'last-will', 'name': 'last-will' },
        { 'id': 'channel-invites', 'name': 'channel-invites' },
        { 'id': 'channel-updates', 'name': 'channel-updates' },
        { 'id': 'user/' + myId, 'name': 'user-settings' },
      ];
      var myTopics = userSettings.topics.map(topicVal => { return { 'id': topicVal.id, 'name': topicVal.name } });
      var mainTopics = myTopics.concat(defaultTopics);
      var actionTopics = myTopics.map(topicVal => { return topicVal.id + "/act" });
      var allTopics = [];

      mainTopics.forEach(topicVal => {
        allTopics.push(topicVal.id);
      });

      actionTopics.forEach(topicVal => {
        allTopics.push(topicVal);
      });

      activeTopics.current = allTopics;

      allTopics.forEach(allTopic => {
        if (allTopic !== undefined)
          mqttClient.subscribe(allTopic);
      });
    }
    mqttClient && setMQTTsubscribe();
  },
    [mqttClient]);


  useEffect(() => {
    if (deletingMessage === null || topicClient.id === null)
      return;

    var newMessages = currentMessages.filter(message => message.timestamp !== deletingMessage);
    var updatedMessages = messages;
    updatedMessages[topicClient.id.replace(/\//g, "")] = newMessages;
    setMessages({ ...updatedMessages });
    setCurrentMessages(newMessages);
    setNoScroll(false);
    setNoScrollAction(false);
    setDeletingMessage(null);
  },
    [deletingMessage, topicClient, currentMessages, messages]);


  useEffect(() => {
    if (topicClient === null || topicClient.id === undefined)
      return;

    var topicClientId = topicClient.id.replace(/\//g, "");
    const currentMessages = messages[topicClientId] === undefined ?
      []
      : messages[topicClientId];
    setCurrentMessages([...currentMessages]);
  },
    [messages, topicClient]);


  useEffect(() => {
    if (topicClient === null || topicClient.id === undefined) {
      if(topicClient === null) {
        setLoadingChannel(false);
      }
      return;
    }
      

    if (messages[topicClient.id.replace('chat/', '').replace(/\//g, "")] === undefined) {
      var fromNew = null;
      userSettings.topics.forEach(item => {
        if (item.id === topicClient.id.replace('chat/', '')) {
          if (item.viewed !== undefined)
            fromNew = item.viewed;
        }
      })

      setNewFrom(fromNew);
    } else {
      setNewFrom(-1);
    }

    var newSettings = userSettings;
    var activeChannel = topicClient;
    delete activeChannel.unread;
    delete activeChannel.viewed;
    activeChannel.id = activeChannel.id.replace('chat/', '');
    newSettings.state.activeChannel = activeChannel;
    newSettings.topics = newSettings.topics.map(topic => {

      if (topic.id === topicClient.id) {
        topic.unread = false;
        if (
          messages[topicClient.id.replace('chat/', '').replace(/\//g, "")] !== undefined
          && messages[topicClient.id.replace('chat/', '').replace(/\//g, "")][0] !== undefined
        ) {
          topic.viewed = messages[topicClient.id.replace('chat/', '').replace(/\//g, "")][0].timestamp;
        }
        return topic;
      }
      topic.id = topic.id.replace('chat/', '');
      return topic;
    });

    setMQTTPublish([
      {
        topic: 'user/' + myId,
        message: newSettings
      }
    ]);

    setLoadingChannel(true);
    setGetHistory(topicClient.id);
  },
    [topicClient]);


  useEffect(() => {
    if (channelPanel.current !== null) {
      channelPanel.current.style.maxHeight = channelPanel.current.scrollHeight + "px";
    }
  },
    [createNewChannel]);

  useEffect(() => {

    if (currentMessages === null)
      return;

    currentMessageElements.current = currentMessageElements.current.filter(element => element !== null);

    if (switchingChannel.current === true) {
      //appMessages.current.scrollTop = appMessages.current.scrollHeight - lastScrollPosition.current;
      switchingChannel.current = false;
      lastScrollPosition.current = 0;
      setNoScrollAction(false);
      return;
    } else if (currentMessageElements.current.length > 0 && !noScroll) {
      //lastScrollPosition.current = appMessages.current.scrollHeight - appMessages.current.clientHeight;
      currentMessageElements.current[currentMessageElements.current.length - 1].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    } else if (currentMessages.length > 0) {
      if (currentMessages[currentMessages.length - 1].username === username && !noScrollAction) {
        // lastScrollPosition.current = appMessages.current.scrollHeight - appMessages.current.clientHeight;
        currentMessageElements.current[currentMessageElements.current.length - 1].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }

    setNoScrollAction(false);
  },
    [currentMessages]);


  useEffect(() => {
    function loginAction() {
      if (loginButton.current.className === false) {
        return;
      } else if (loginButton.current.className === 'App-Login LoggingIn') {
        return;
      }
      loginButton.current.className = 'App-Login LoggingIn';
      usernameInput.current.disabled = true;
      switchingChannel.current = true;
    }
    login && loginAction();
  },
    [login]);


  useEffect(() => {
    function reconnectChecks() {
      if (reconnectCount > 5) {
        mqttClient.end();
        setConnectionError(true);
        setMQTTClient(null);
        setIsLoggedIn(false);
        setLoginError('Connection error');
      }
    }
    reconnectChecks();
  },
    [reconnectCount, mqttClient, isLoggedIn]);


  useEffect(() => {
    function activeUserMessage() {
      if (activeUsersMessage.current === null)
        return;

      if (activeUsers.length === 0) {
        activeUsersMessage.current.innerText = '';
        return;
      } else if (activeUsers.length > 2) {
        activeUsersMessage.current.innerText = 'Multiple users are typing';
        return;
      }
      var newActiveMessage = "";
      var userAlias = '';
      activeUsers.forEach(
        user => {
          usersOnline.forEach(usera => {
            if (usera.id === user.id) {
              userAlias = usera.alias;
              return;
            }
          })
          newActiveMessage += userAlias + " is typing";
        }
      );
      activeUsersMessage.current.innerText = newActiveMessage;
    }
    activeUsers && activeUserMessage();
  },
    [activeUsers, usersOnline]);


  useEffect(() => {
    function deleteUserMessage() {
      if (deleteMessage !== null) {
        setMQTTPublish([
          {
            topic: 'chat/' + topicClient.id,
            message: {
              'id': myId,
              'timestamp': deleteMessage,
              'topic': topicClient.id,
              'msgtype': 'message'
            }
          }
        ]);
        setNoScroll(true);
        setDeleteMessage(null);
        setNoScrollAction(true);
        setEditMessage(null);
      }
    }
    deleteMessage && deleteUserMessage()
  },
    [deleteMessage, topicClient, mqttClient, myId]);


  useEffect(() => {
    function editUserMessage() {
      if (editMessage === null) {
        if (editIndex.current !== null) {
          currentMessageElements.current[editIndex.current].classList.remove('editing');
        }
        editIndex.current = null;
        setEditMessageDocs([]);
        return;
      }
      if (editMessage.message.document !== undefined) {
        setEditMessageDocs(editMessage.message.document);
        setAttachedDocuments({ document: editMessage.message.document });
      }

      messageInput.current.value = editMessage.message.text;
      quillRef.current.editor.root.innerHTML = editMessage.message.text;
    }
    editMessage && editUserMessage()
  },
    [editMessage]);


  useEffect(() => {
    async function getChannelHistory() {
      if (getHistory === null || topicClient.id === undefined) {
        return;
      }

      const currentTopic = topicClient.id.replace(/\//g, "");

      if (loadingChannel) {
        if (messages[currentTopic] !== undefined) {
          setCurrentMessages(messages[currentTopic]);
          setHasLoadMore(channelHistoryPositions[currentTopic] !== undefined);
          setLoadingChannel(false);
          setNoScroll(true);
          setNoScrollAction(true);
          return;
        }
      }

      var headers = {
        "Accept": "application/json",
        "Authorization": userSettings.t,
        "uuid": myId
      };

      var url = endPoints.history + "?id=" + topicClient.id.replace('chat/', '');

      if (loadingMore) {
        if (channelHistoryPositions[currentTopic] === undefined) {
          return;
        }
        url += "&l=" + channelHistoryPositions[currentTopic];
      }

      return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: headers
      })
        .then(res => {
          if (!res.ok) {
            if (res.status === 404) {
              return { data: [] };
            }
          }
          return res.json();
        })
        .then(results => {
          var incomingMessage = results.data;
          var visibleMessages = [];
          var hasLoadMoreMessages = false;

          if (incomingMessage.length > 0) {

            var lastViewed = 0;
            userSettings.topics.forEach(item => {
              if (item.id === topicClient.id) {
                lastViewed = item.viewed !== undefined ? item.viewed : 0;
              }
            })

            if (lastViewed < incomingMessage[0].timestamp) {
              setLastViewedChannelAt({ timestamp: incomingMessage[0].timestamp, ...JSON.parse(incomingMessage[0].message) });
            }

            incomingMessage.forEach(
              item => {
                item.message = JSON.parse(item.message);
                if (item.message.document !== undefined) {
                  addImageToLocalStorage(item.message.document, item.username, item.timestamp);
                }

                if ('username' in item) {
                  visibleMessages.unshift(item);
                } else if ('more' in item) {
                  channelHistoryPositions[currentTopic] = item.more.timestamp;
                  setChannelHistoryPositions({ ...channelHistoryPositions });
                  hasLoadMoreMessages = true;
                }
              }
            );
          }

          if (!hasLoadMoreMessages) {
            delete channelHistoryPositions[currentTopic];
            setChannelHistoryPositions({ ...channelHistoryPositions });
          }

          if (messages[currentTopic] !== undefined) {
            visibleMessages = visibleMessages.concat(messages[currentTopic]);
          }

          messages[currentTopic] = visibleMessages;

          setMessages({ ...messages });

          if (loadingMore === true) {
            //appMessages.current.scrollTop = lastScrollPosition.current;
            switchingChannel.current = true;
          }

          setHasLoadMore(hasLoadMoreMessages);
          setLoadingMore(false);
          setLoadingChannel(false);

        })
        .catch(err => {
          console.log(err);
        });
    }
    Promise.all([getChannelHistory()]).then(() => {
      setGetHistory(null);
    });
  },
    [getHistory]);


  useEffect(() => {
    if (newMessage === null)
      return;

    function addMessage(message) {

      var newMessages = messages,
        messageTopic = message.topic.replace('chat/', '').replace(/\//g, "");

      if (newMessages[messageTopic] === undefined) {
        newMessages[messageTopic] = [];
      }

      var existingMessage = newMessages[messageTopic].filter(msg => msg.timestamp === message.timestamp);
      if (existingMessage.length > 0) {
        newMessages[messageTopic] = newMessages[messageTopic].map(msg => {
          if (msg.timestamp === message.timestamp) {
            return message;
          }
          return msg;
        });
        setMessages({ ...messages, ...newMessages });
        return;
      }

      newMessages[messageTopic].push(message);

      if (message.message.document !== undefined) {
        addImageToLocalStorage(message.message.document, message.username, message.timestamp);
      }

      setMessages({ ...messages, ...newMessages });

      if (message.topic.replace('chat/', '') === topicClient.id) {
        setLastViewedChannelAt(message);
      }

      if (message.username !== myId && audioEnabled && message.topic.replace('chat/', '') === topicClient.id) {
        const audio = new Audio('chime.mp3');
        audio.play();
      }

      if (message.username !== myId && message.topic.replace('chat/', '') !== topicClient.id) {
        var channelIndex;
        var channel = channelList.filter((channel, index) => {
          if (channel.id === message.topic) {
            channelIndex = index;
            return channel.id === message.topic;
          } else {
            return false;
          }
        });

        var newChannelList = channelList.filter(channel => channel.id !== message.topic);

        if (channel.length > 0) {
          channel[0].unread = true;
          newChannelList.splice(channelIndex, 0, channel[0]);
          setChannelList(newChannelList);
        }
      }
    }

    addMessage(newMessage);
    setNewMessage(null);
  },
    [newMessage]);


  useEffect(() => {
    if (!loadingChannel && isLoggedIn) {
      //  appMessages.current.scrollTop = appMessages.current.scrollHeight;
    }
  },
    [loadingChannel, topicClient, isLoggedIn]);


  useEffect(() => {
    if (usersOnline.length === 0 || panelOpen !== 0)
      return;
    accordionOnClick(0, true);
  },
    [usersOnline, panelOpen]);


  useEffect(() => {
    if (usersOnlinePanel.current !== null && panelOpen !== null) {
      accordionOnClick(panelOpen[0]);
    }
  },
    [panelOpen])


  useEffect(() => {
    if (userSettings.state === undefined)
      return;

    if (userSettings.state.audioEnabled === audioEnabled)
      return;

    var newSettings = { ...userSettings, state: { ...userSettings.state, audioEnabled: audioEnabled } };

    setMQTTPublish([
      {
        topic: 'user/' + myId,
        message: newSettings
      }
    ]);
  },
    [audioEnabled]);


  const addImageToLocalStorage = (document, username, timestamp) => {
    document.forEach(async (file, index) => {
      var imageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (imageTypes.includes(file.type)) {
        var url = endPoints.signed + '?file=' + encodeURIComponent(file.path) + '&type=get';
        var id = username + '-' + timestamp + index;
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "Accept": "application/json",
            "Authorization": userSettings.t,
            "uuid": myId,
          }
        })
          .then(res => res.json())
          .then(async tokens => {
            await axios.get(tokens.url, {
              responseType: 'blob'
            }).then(response => {
              const reader = new FileReader();
              reader.onload = function (e) {
                localStorage.setItem(id.replace("-", ""), e.target.result);
              }
              reader.readAsDataURL(response.data);
            }).catch(error => {
              console.log(error);
              var not_found = new Image();
              not_found.src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
              localStorage.setItem(id.replace("-", ""), not_found.src);
            });
          });
      }
    });
  }


  const stickyScrollCheck = () => {
    if (appMessages.current !== null) {
      if (
        appMessages.current.scrollTop === appMessages.current.scrollHeight - appMessages.current.clientHeight
        ||
        username === currentMessages[currentMessages.length - 1].username
      ) {
        setNoScroll(false);
      } else {
        setNoScroll(true);
      }
    }
  }


  const accordionOnClick = (index, resize = false) => {
    var acc = document.getElementsByClassName("accordion");
    var panel = document.getElementsByClassName("panel");
    if (panel[index].style.maxHeight && !resize) {
      panel[index].style.maxHeight = null;
      acc[index].classList.remove('active');
    } else {
      if (!resize) {
        for (var i = 0; i < acc.length; i++) {
          panel[i].style.maxHeight = null;
          acc[i].classList.remove('active');
        }
      }
      panel[index].style.maxHeight = panel[index].scrollHeight + "px";
      acc[index].classList.add('active');
    }
  }


  const decryptMessage = (message, id) => {
    const key = crypto
      .createHash('sha512')
      .update(id)
      .digest('hex')
      .substring(0, 32)
    const encryptionIV = crypto
      .createHash('sha512')
      .update(userToken.current)
      .digest('hex')
      .substring(0, 16)
    const decipher = crypto.createDecipheriv('aes-256-cbc', key, encryptionIV);
    var deciphed = decipher.update(message, 'base64', 'utf8');
    deciphed += decipher.final('utf8');
    return JSON.parse(deciphed);
  }


  const userChatSettings = endPoint => {
    return fetch(endPoint + "?id=" + username.toString() + "&token=true", {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Accept": "application/json",
        "Authorization": passcode,
      }
    })
      .then(async res => {
        var result = await res.json();
        return result.data;
      })
      .then(async settings => {
        var activeTopic = settings.state.activeChannel;

        settings.topics = settings.topics.map(topic => {
          topic.id = 'chat/' + topic.id;
          return topic;
        });

        if (activeTopic === null) {
          activeTopic = settings.topics[0];
          settings.state.activeChannel = activeTopic;
        }
        await Promise.all([
          setUserSettings(settings),
          setMyId(settings.id),
          setTopicClient(activeTopic),
          setAudioEnabled(settings.state.audioEnabled !== undefined ? settings.state.audioEnabled : true),
          setChannelList(settings.topics),
          setUsername(settings.username),
        ]);

        setTimeDiff(settings.timestamp - Date.now());
        return settings;
      }).catch(err => {
        console.log(err);
      });
  }


  const clearEditor = () => {
    quillRef.current.editor.root.innerHTML = "";
    quillRef.current.editor.root.setAttribute('data-placeholder', 'Enter your message here');
    setAttachedDocuments({ document: [] });
    setEditMessage(null);
  }


  const clearWYSIWYG = e => {
    e.preventDefault();
    clearEditor();
    setEditMessage(null);
    setDeleteFile(null);
    if (currentMessageElements.current[editIndex.current] !== undefined)
      currentMessageElements.current[editIndex.current].classList.remove('editing');
  }


  const MQTTConnect = (mqttUrl, id, alias) => {
    var options = {
      will: {
        topic: 'last-will',
        payload: JSON.stringify({ id: id, alias: alias }),
      },
      clientId: id,
      protocolId: 'MQTT',
      protocolVersion: 5,
      qos: 0,
      clean: true,
      headers: {
        host: mqttUrl
      }
    };

    try {
      const client = mqtt.connect(mqttUrl, options);
      client.on(
        'connect',
        () => {
          setMQTTPublish([
            {
              topic: 'client-connected',
              message: { 'id': id, 'alias': alias }
            }
          ]);
        }
      );

      client.on(
        'message',
        (topic, message) => {
          new Promise(async resolve => {
            if (topic === 'user/' + id) {
              var messageDecoded = JSON.parse(message.toString());
              messageDecoded = decryptMessage(messageDecoded.data, id);
              if (messageDecoded.close !== undefined) {
                if (messageDecoded.close === true) {
                  console.log('Connection closed');
                  client.end();
                }
              } else {
                await Promise.all([
                  setUserSettings(messageDecoded),
                  setChannelList(messageDecoded.topics),
                  setUsername(messageDecoded.username),
                ]);
              }
              return;
            }
            var messageDecoded = JSON.parse(message.toString());
            if (messageDecoded.message !== undefined) {
              topic = topic.replace('chat/', '');
              if (messageDecoded.message.part !== undefined) {

                let currentParts = localStorage.getItem('messageParts_' + messageDecoded.timestamp);
                if (currentParts === null) {
                  currentParts = [];
                } else {
                  currentParts = JSON.parse(currentParts);
                }

                currentParts.push({ topic: topic, message: message.toString() });

                if (currentParts.length === messageDecoded.message.totalParts) {
                  var totalMessage = '';
                  currentParts.sort((a, b) => {
                    return JSON.parse(a.message).message.part - JSON.parse(b.message).message.part;
                  });

                  currentParts.forEach((part, index) => {
                    var decodedPart = JSON.parse(part.message);
                    totalMessage += decodedPart.message.text;
                  });
                  localStorage.removeItem('messageParts_' + messageDecoded.timestamp);
                  messageDecoded.message.text = totalMessage;

                  var currentAppMessages = appMessagesRef.current;
                  if (currentAppMessages === null) {
                    currentAppMessages = [];
                  }
                  currentAppMessages.push({ topic: topic, message: JSON.stringify(messageDecoded) });
                  appMessagesRef.current = currentAppMessages;
                  resolve(true);
                } else {
                  localStorage.setItem('messageParts_' + messageDecoded.timestamp, JSON.stringify(currentParts));
                  resolve(false);
                }
              } else {
                var currentAppMessages = appMessagesRef.current;
                if (currentAppMessages === null) {
                  currentAppMessages = [];
                }
                currentAppMessages.push({ topic: topic, message: message.toString() });
                appMessagesRef.current = currentAppMessages;
                resolve(true);
              }
            } else {
              var currentAppMessages = appMessagesRef.current;
              if (currentAppMessages === null) {
                currentAppMessages = [];
              }
              currentAppMessages.push({ topic: topic, message: message.toString() });
              appMessagesRef.current = currentAppMessages;
              resolve(true);
            }
          }).then((result) => {
            if (result) {
              setProcessPacket(true);
            }
          });
        }
      );

      setIsLoggedIn(true);
      return client;

    } catch (err) {
      console.log(err)
    }
  }


  const handleSubmit = async e => {
    e.preventDefault();

    if (messageInput.current.value.length > 2000) {

    }

    if (messageInput.current.value === '' || messageInput.current.value === '<p><br></p>') {
      quillRef.current.editor.root.setAttribute('data-placeholder', 'Please enter a message');
      return;
    }

    const totalMessage = messageInput.current.value;
    const parts = totalMessage.match(/.{1,180}/g);
    const totalParts = parts.length;
    var documentNode = [];
    var messageTimestamp = Date.now();// + timeDiff;

    if (attachedDocuments.document.length > 0) {
      const docs = await Promise.all(
        attachedDocuments.document.map(
          async file => {
            var exists = false;
            editMessageDocs.forEach(doc => {
              if (doc.name === file.name) {
                exists = true;
                return;
              }
            });

            if (exists) {
              if (file.width !== undefined && file.height !== undefined)
                return { name: file.name, path: file.path, type: file.type, width: file.width, height: file.height };
              else
                return { name: file.name, path: file.path, type: file.type };
            }

            setFileUploading(file.name.replace(".", ""));

            const filePutSignedUrl = {
              method: 'GET',
              mode: 'cors',
              headers: {
                "Accept": "application/json",
                "Authorization": userSettings.t,
                "uuid": myId
              }
            };

            var encodedFilename = encodeURIComponent(topicClient.id + "/" + myId + "/" + file.name);
            var url = endPoints.signed + "?file=" + encodedFilename + "&type=put";

            return fetch(url, filePutSignedUrl)
              .then(res => res.json())
              .then(async tokens => {
                var s3puturl = tokens.url;

                axios.put(s3puturl, file.contents, {
                  headers: {
                    'Content-Type': file.type,
                    'Content-Encoding': 'binary',
                  }
                });

                if (file.type.startsWith('image/')) {
                  const img = new Image();
                  img.src = URL.createObjectURL(file.file);
                  await new Promise(resolve => {
                    img.onload = () => {
                      file.width = img.width;
                      file.height = img.height;
                      resolve();
                    };
                  });
                }

                if (document.getElementById('attachment_' + file.name.replace(".", "")) !== null)
                  document.getElementById('attachment_' + file.name.replace(".", "")).classList.add("uploaded");
                if (file.width !== undefined && file.height !== undefined)
                  return { name: file.name, path: topicClient.id + "/" + myId + "/" + file.name, type: file.type, width: file.width, height: file.height };
                else
                  return { name: file.name, path: topicClient.id + "/" + myId + "/" + file.name, type: file.type };
              });
          }));

      documentNode = { document: docs };
    }

    setFileUploading(false);
    setAttachedDocuments({ document: [] });
    var editing = editMessage !== null;
    if (editing) {
      messageTimestamp = editMessage.timestamp;
      currentMessageElements.current[editIndex.current].classList.remove('editing');
      setNoScroll(true);
      setDeleteMessage(null);
      setNoScrollAction(true);
    }

    var tmpTopicClient = "chat/" + topicClient.id;

    for (var i = 1; i < totalParts + 1; i++) {
      var payload = {
        username: myId,
        message: { alias: username, text: parts[i - 1], totalParts: totalParts, part: i, edited: editing },
        timestamp: messageTimestamp,
        topic: tmpTopicClient.toString(),
        msgtype: 'message'
      };
      if (documentNode !== null && i === totalParts) {
        payload.message.document = documentNode.document;
      }
      mqttClient.publish(tmpTopicClient, JSON.stringify(payload));
    }

    quillRef.current.editor.root.setAttribute('data-placeholder', 'Enter your message here');
    quillRef.current.editor.deleteText(0, totalMessage.length);
    setEditMessage(null);
    setDeleteFile(null);
  }


  const handleLogin = async e => {
    e.preventDefault();
    const requestMetadata = {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Accept": "application/json",
        "Authorization": passcode,
        "uuid": username
      }
    };

    setLogin(true);
    return await fetch(urlMQTT, requestMetadata)
      .then(res => res.json())
      .then(async result => {
        if (result.message === 'Access denied') {
          setLoginError('Access Denied');
          return false;
        }
        setLoginError(null);

        const apiEndpoints = await fetch(urlAPI, requestMetadata)
          .then(res => res.json())
          .then(result => {
            return result.data;
          });

        setEndPoints(apiEndpoints);
        const loggedInUser = await userChatSettings(urlUser);
        userToken.current = loggedInUser.t;
        return [result.url, loggedInUser.id, loggedInUser.username];
      }).then(async result => {
        if (result !== false) {
          return await new Promise(resolve => {
            setMQTTClient(MQTTConnect(...result));
            resolve(true);
          }).then(() => {
            setConnectionError(false);
            setLogin(false);
            setLoadingChannel(true);
            return true;
          }).catch(err => {
            console.log(err);
            setLogin(false);
          })
        }
        return false;
      }).catch(err => {
        console.log(err);
        setLogin(false);
      });
  }


  const handleLogout = async e => {
    e.preventDefault();
    try {
      setIsLoggedIn(false);
      setMQTTPublish([
        {
          topic: 'client-disconnected',
          message: { id: myId, alias: userSettings.username }
        }
      ]);

      activeTopics.current.forEach(topicVal => {
        mqttClient.unsubscribe(topicVal);
      });

      mqttClient.end(true);
      await new Promise((resolve) => {
        setMQTTClient(null);
        resolve(true);
      }).then(async () => {
        await Promise.all([
          setMyId(''),
          setUsername(''),
          setNewMessage(null),
          setMessages({}),
          setChannelList([]),
          setTimeDiff(null),
          setEndPoints([]),
          setChannelHistoryPositions([]),
          setLoadingChannel(true),
          setAttachedDocuments({ document: [] }),
          setEditMessage(null),
          setDeleteMessage(null),
          setEditMessageDocs([]),
          setHasLoadMore(false),
          setCurrentMessages(null),
          setUserSettings({}),
          setAudioEnabled(true),
          setPasscode(null),
          setTopicClient(null),
          setNoScroll(false),
          setNoScrollAction(false),
          setLoadingMore(false),
          setDeleteFile(null),
          setCreateNewChannel(false),
          setFileUploading(false),
          setLoginError(null),
          setLogin(false),
          setConnectionError(false),
          setReconnectCount(0),
          setLastActiveTime(0),
          setIamActive(0),
          dispatchUsers({ type: 'clear' }),
          dispatchOnline({ type: 'clear' }),
          () => { fileList.current = []; return true; },
          () => { currentMessageElements.current = []; return true; },
          () => { channelName.current = []; return true; },
          () => { userListRefs.current = []; return true; },
          () => { friendsName.current = []; return true; },
          () => { dropzoneElement.current = []; return true; },
          () => { newChannelName.current = []; return true; },
          () => { loginButton.current = []; return true; },
          () => { messageInput.current = []; return true; },
          () => { appMessages.current = []; return true; },
          () => { usernameInput.current = []; return true; },
          () => { quillRef.current = []; return true; },
          () => { editIndex.current = []; return true; },
          () => { switchingChannel.current = []; return true; },
          () => { lastScrollPosition.current = []; return true; },
          () => { activeTopics.current = []; return true; },
          () => { appMessagesRef.current = []; return true; },
          () => { localStorage.clear(); return true; }
        ]);
      })
    } catch (err) {
      window.location.reload();
    }
  }


  if (isLoggedIn) {
    return (
      <div className="App">
        <WindowEvents />
        <FileDropzone
          dropzoneElement={dropzoneElement}
          setAttachedDocuments={setAttachedDocuments}
          attachedDocuments={attachedDocuments}
          setEditMessageDocs={setEditMessageDocs}
          editMessageDocs={editMessageDocs}
          deleteFile={deleteFile}
        />
        <header className="App-header">
          {topicClient !== null ?
          <h2>
            <span>{topicClient.name !== undefined ? topicClient.name.replace('chat/', '') : null}</span><br />
            <span>{topicClient.id !== undefined ? topicClient.id : null}</span>
          </h2>
          : null}
          <div className="logged_in_as">
            <strong>{username}</strong><br />
            <span>{myId}</span> <button className="App-Logout" onClick={handleLogout}>
              <span>
                Logout
              </span>
            </button>
            {connectionError ? <div>
              <span>
                There was an error connecting to the chat server.  Please try again later.
              </span>
            </div> : null}
            <span className="audio-toggle" onClick={() => setAudioEnabled(!audioEnabled)}>{audioEnabled ? '🔊' : '🔇'}</span>
          </div>
          <div className='App-body'>
            <div className="App-references">
              <div className={'accordion'} >
                <h4 id="App-userlist" onClick={() => { setPanelOpen([0, panelOpen === 0]) }}>
                  <span>
                    Users Online
                  </span>
                </h4>
                <ul id="App-userlist-list" className={"panel"} ref={usersOnlinePanel} key={'usersonline'}>
                  {usersOnline.map((user) => {
                    return (
                      <li key={user.id + "uo"} ref={el => userListRefs.current[user.id] = el} onClick={(e) => {
                        e.preventDefault();
                        setSelectedUser(user);
                        if (document.getElementsByClassName('selected')[0] !== undefined) {
                          document.getElementsByClassName('selected')[0].classList.remove('selected');
                        }
                        e.target.parentElement.classList.add('selected');
                      }}><div className={'userlist_name'}>{user.alias}</div>
                        {selectedUser !== null && user.id === selectedUser.id ? <div className="user_info">
                          <div className="invite_to_chat"
                            onClick={(e) => {
                              e.preventDefault();
                              if (user.id > myId) {
                                var newChannelName = 'chat/d/' + myId + '/' + user.id;
                              } else {
                                var newChannelName = 'chat/d/' + user.id + '/' + myId;
                              }
                              var newChannel = { 'name': user.alias, 'id': newChannelName };
                              var newChannels = channelList;
                              newChannels.push({ ...newChannel });
                              setChannelList(newChannels);
                              const newSettings = { ...userSettings, topics: newChannels }
                              setUserSettings(newSettings);
                              mqttClient.subscribe([newChannelName, newChannelName + "/act"]);
                              activeTopics.current.push(newChannelName);
                              console.log("hit update aa");
                              var mqttMessages = [
                                {
                                  topic: 'user/' + myId,
                                  message: newSettings
                                },
                                {
                                  topic: 'user/' + user.id,
                                  message: { 'invite': newChannelName, 'alias': username }
                                },
                                {
                                  topic: 'direct/' + user.id,
                                  message: { 'invite': newChannelName, 'alias': username }
                                }
                              ];
                              setMQTTPublish(mqttMessages);
                              setSelectedUser(null);
                              accordionOnClick(2);
                              switchingChannel.current = true;
                              setLoadingChannel(true);
                              setTopicClient(newChannel);
                              if (document.getElementsByClassName('selected')[0] !== undefined) {
                                document.getElementsByClassName('selected')[0].classList.remove('selected');
                              }
                            }}
                          ></div>
                        </div> : null}
                      </li>
                    );
                  })}
                  {usersOnline.length === 0 ? <li>No users online</li> : null}
                </ul>
              </div>

              <div className={'accordion'}>
                <h4 onClick={() => { setPanelOpen([1, panelOpen === 1]) }}>
                  <span>
                    Direct Messages
                  </span>
                </h4>

                <ul id="App-direct-messages-list" className={'panel'} ref={channelPanel}>
                  {channelList !== undefined && channelList.map((channel) => {
                    if (channel.name.substr(channel.id.length - 3, 3) !== 'act' && channel.id.substr(0, 7) === 'chat/d/')
                      return (
                        <li key={channel.id + "ci"} ref={el => channelName.current[channel.id] = el}
                          onClick={(e) => {
                            e.preventDefault();
                            var cachedMessages = false;
                            if (messages[channel.id.replace(/\//g, "")] !== undefined) {
                              cachedMessages = true;
                            }

                            if (channel.unread !== undefined && channel.unread) {
                              channel.unread = false;
                              setChannelList(channelList);
                            }

                            switchingChannel.current = true;
                            setLoadingChannel(!cachedMessages);
                            setTopicClient(channel);
                          }}><span className={topicClient !== null && topicClient.id !== undefined  && topicClient.id === channel.id ? "active_topic" : null}>
                            {channel.name}
                            {channel.unread !== undefined && channel.unread ?
                              <span className={'new_message_notifications'}>
                                <span className={'new_message_notice'}></span>
                              </span>
                              : null}
                          </span>
                        </li>
                      );
                    return false;
                  })}
                </ul>
              </div>
              <div className={'accordion'}>
                <h4 onClick={() => { setPanelOpen([2, panelOpen === 2]) }}>
                  <span>
                    Channels
                  </span>
                </h4>
                <ul id="App-channels-list" className={'panel'} ref={channelPanel}>
                  {channelList !== undefined && channelList.map((channel) => {
                    if (channel.name.substr(channel.id.length - 3, 3) !== 'act' && channel.id.substr(0, 7) !== 'chat/d/')
                      return (
                        <li key={channel.id + "ci"} ref={el => channelName.current[channel.id] = el}
                          onClick={(e) => {
                            e.preventDefault();
                            var cachedMessages = false;
                            if (messages[channel.id.replace(/\//g, "")] !== undefined) {
                              cachedMessages = true;
                            }

                            if (channel.unread !== undefined && channel.unread) {
                              channel.unread = false;
                              setChannelList(channelList);
                            }

                            switchingChannel.current = true;
                            setLoadingChannel(!cachedMessages);
                            setTopicClient(channel);
                          }}><span className={topicClient !== null && topicClient.id !== undefined  && topicClient.id === channel.id ? "active_topic" : null}>
                            {channel.name.replace('chat/', '')}
                            {channel.unread !== undefined && channel.unread ?
                              <span className={'new_message_notifications'}>
                                <span className={'new_message_notice'}></span>
                              </span>
                              : null}
                            {channel.key !== undefined ?
                              <span className={'channel_admin_options'}>
                                <span className={'admin_options'}>X</span>
                                <span className={'admin_messages'}>X</span>
                              </span>
                              : null}
                          </span>
                        </li>
                      );
                    return false;
                  })}
                </ul>
              </div>
            </div>
            <div className={"App-messages-block"}>
              <div className={"App-messages"} ref={appMessages} onScroll={stickyScrollCheck}>
                {hasLoadMore && !loadingChannel ? <div className="more-messages" onClick={() => {
                  lastScrollPosition.current = appMessages.current.scrollHeight;
                  setLoadingMore(true);
                  setGetHistory(topicClient.id);
                }}>
                  {loadingMore && !loadingChannel ?
                    <img src="loader2.gif" alt="loading" className={"loading_norm"} /> :
                    <span className={"load_more"}>
                      Load more messages...
                    </span>
                  }
                </div> : null}
                {loadingChannel ? <div id="App-messages-placeholder">
                  <div>
                    <span>
                      Loading messages...
                    </span>
                    <br />
                    <img src="loader2.gif" alt="loading" className={"loading_norm"} />
                  </div>
                </div> : null}
                {!loadingChannel && currentMessages !== undefined && currentMessages.length === 0 ? <div id="App-messages-placeholder">
                  <span>
                    No messages in this channel yet.
                  </span>
                  <br />
                  <span>
                    Welcome to HROChat.  You are now connected, new messages will appear here.
                  </span>
                </div> : null}
                {!loadingChannel && currentMessages !== undefined && currentMessages.length > 0 ? currentMessages.map((message, index) => {
                  var day = new Date(message.timestamp).toLocaleDateString();
                  var previousMessage = currentMessages[currentMessages.indexOf(message) + 1];
                  var previousDay = previousMessage !== undefined ? new Date(previousMessage.timestamp).toLocaleDateString() : null;
                  return (
                    <div key={'message-' + index}>
                      <MessageLineComponent
                        ref={el => currentMessageElements.current[index] = el}
                        setAttachedDocuments={setAttachedDocuments}
                        message={message}
                        key={message.timestamp}
                        index={index}
                        editIndex={editIndex}
                        myId={myId}
                        setEditMessage={setEditMessage}
                        setDeleteMessage={setDeleteMessage}
                        setDeletingMessage={setDeletingMessage}
                        passcode={userSettings.t}
                        signed={endPoints.signed}
                      />
                      {day !== previousDay && previousDay !== null ? <span className="new-day-marker">{day}</span> : null}
                      {previousMessage !== undefined && message.timestamp === newFrom ? <span id={"new_marker"}>NEW MESSAGES</span> : null}
                    </div>)
                }) : null}
              </div>
              <div className="channel_activity" ref={activeUsersMessage}></div>
              <form
                className="App-form"
                onSubmit={handleSubmit}
              >
                <textarea
                  type="text"
                  id="message"
                  ref={messageInput}
                />
                <QuillComponent
                  placeholder={"Enter your message here"}
                  quillRef={quillRef}
                  setIamActive={setIamActive}
                />
                <div className="action_buttons">
                  <button
                    className={"attach"}
                    onClick={(e) => {
                      e.preventDefault();
                      dropzoneElement.current.click(e);
                    }}
                  >
                  </button>
                  <div className={"attached_documents"}>
                    <ul className={"document_list"}>
                      {attachedDocuments.document.map((file, index) =>
                        <AddedDocumentComponent
                          file={file}
                          key={index}
                          fileUploading={fileUploading}
                          delete={() => { setDeleteFile(file); }}
                        />
                      )}
                    </ul>
                  </div>
                  <div className={"action_button_block"}>
                    <button>
                      {editMessage !== null ? 'Update' : 'Send'}
                    </button>
                    <button
                      className={"clear"}
                      onClick={clearWYSIWYG}
                    >
                      {editMessage !== null ? 'Cancel' : 'Clear'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </header>
      </div>
    );
  } else {
    return (
      <div className={"App"}>
        <header className={"App-header"}>
          <h1>
            HROChat
          </h1>
          {loginError}
          <form
            className={"App-form App-login-form"}
            onSubmit={handleLogin}
          >
            <input
              type="text"
              ref={usernameInput}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="UUID"
            />
            <button ref={loginButton} className="App-Login">Login</button>
            <div className="login_notice">
              <span>
                This is a proof of concept and feasibility study into the solution
              </span>
            </div>
            {connectionError ? <div>
              <span>
                There was an error connecting to the chat server.  Please try again later.
              </span>
            </div> : null}
            <div>
              <span>
              </span>
              <input type="text" id="code" name="code" value={passcode} onChange={(e) => setPasscode(e.target.value)} />
            </div>
          </form>
        </header>
      </div>
    );
  }
}

export default App;