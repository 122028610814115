import { useEffect, useRef } from 'react';

function AddedDocumentComponent(props) {
    const file = props.file;
    const docRef = useRef();
    const id = "attachment_" + props.file.name.replace(".", "");
    useEffect(() => {
        if (props.fileUploading === props.file.name.replace(".", "")) {
            docRef.current.classList.add("uploading");
        }
    }
        , [props.fileUploading]);

    return (
        <li
            ref={docRef}
            id={id}
        >
            {file.name}
            <img
                className={"uploader_spinner"}
                src="./loader.gif" alt="uploading" />
            <span
                onClick={() => props.delete()}
                className={"remove_file"}>
                ❌
            </span>
        </li>
    );
}

export default AddedDocumentComponent;