import axios from 'axios';

function MessageDocumentComponent(props) {
    const tokenUrl = props.signed;

    const getDownloadUrl = (id) => {
        if (localStorage.getItem(id.replace("-", "")) !== null) {
            if (document.getElementById(id.replace("-", "")) !== null) {
                document.getElementById(id.replace("-", "")).src = localStorage.getItem(id.replace("-", ""));
            }
        }
        return id.replace("-", "");
    }

    const streamFileDownload = file => {
        return function (e) {
            e.preventDefault();
            var url = tokenUrl + '?file=' + encodeURIComponent(file.path) + '&type=get';
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "Accept": "application/json",
                    "Authorization": props.passcode,
                    "uuid": props.uuid,
                }
            })
                .then(res => res.json())
                .then(async tokens => {
                    await axios.get(tokens.url, {
                        responseType: 'blob'
                    })
                        .then(response => {
                            const a = document.createElement("a");
                            a.style.display = "none";
                            document.body.appendChild(a);
                            const fileStream = new Blob([response.data], { type: file.type });
                            const url = window.URL.createObjectURL(fileStream);
                            a.href = url;
                            a.download = file.name;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        });
                });
        }
    }

    const image_types = ['image/jpeg', 'image/png', 'image/gif'];
    const doc_types = ['application/pdf', 'application/text'];
    const attachment = props.attachment;
    const id = props.id;
    const index = props.index;
    const file_type = props.file_type;

    if (attachment === null) {
        return (<div></div>);
    }
    if (file_type === 'image' && image_types.includes(attachment.type)) {
        return (
            <div key={id + index} className={"message_attachment_link message_attachment_link_image"}>
                {image_types.includes(attachment.type) ? <img draggable="false" className={'doc_image'} width={attachment.width} height={attachment.height} src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" id={getDownloadUrl(id + index)} alt={attachment.name} /> : null}
            </div>
        );
    } else if (file_type === 'document') {
        return (
            <div key={id + index} className={"message_attachment_link message_attachment_link_download"}>
                {doc_types.includes(attachment.type) ? <span draggable="false" className="file_download" onClick={streamFileDownload(attachment)}>{attachment.name}</span> : null}
            </div>
        );
    }
}

export default MessageDocumentComponent;