import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDropzone } from 'react-dropzone'

function FileDropzone(props) {

  const [files, setFiles] = useState([]);
  const currentFiles = useRef([]);

  useEffect(() => {
    if (props.deleteFile !== null) {
      currentFiles.current = currentFiles.current.filter((file) => file.name !== props.deleteFile.name);
      if( props.editMessageDocs.length > 0 )
        props.setEditMessageDocs(props.editMessageDocs.filter((file) => file.name !== props.deleteFile.name));
      setFiles(currentFiles.current);
      props.setAttachedDocuments({ document: currentFiles.current });
    }
  }
    , [props.deleteFile]);

  useEffect(() => {
    currentFiles.current = props.attachedDocuments.document;
  }
    , [props.attachedDocuments]);


  useEffect(() => {
    props.setAttachedDocuments({ document: files });
  }
    , [files]);

  const onDrop = useCallback(async acceptedFiles => {
    var newFiles = [];
    var filePromises = [];
    document.getElementById('dropzone').classList.remove("drophover");
    acceptedFiles.forEach((file) => {
      filePromises.push(new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = function (e) {
            resolve({ name: file.name, contents: e.target.result, type: file.type, file: file });
          }
          reader.readAsArrayBuffer(file);
        } catch (e) {
          reject(e);
        }
      }).then((file) => {
        newFiles.push(file);
        return file;
      }));
    });
    await Promise.all(filePromises);
    currentFiles.current = currentFiles.current.concat(newFiles);
    setFiles(currentFiles.current);
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} id={'dropzone'} ref={props.dropzoneElement} className={'dropzone'}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <div className={"dropstyleactive"}>
            <div className={"blind"}>
            </div>
            <div className={"notice"}>
              ☁️
              Drag 'n' drop some files here
            </div>
          </div> :
          null
      }
    </div>
  )
}

export default FileDropzone;