import { forwardRef } from 'react';
import parse from 'html-react-parser';
import MessageDocumentComponent from './messagedocument';

const MessageLineComponent = forwardRef((props, ref) => {
  const message = props.message;
  const myId = props.myId;
  const setEditMessage = props.setEditMessage;
  const setDeleteMessage = props.setDeleteMessage;
  const setDeletingMessage = props.setDeletingMessage;
  const editIndex = props.editIndex;
  const id = message.username + '-' + message.timestamp;
  const index = props.index;

  return (
    <div className={'App-message'} data-timestamp={message.timestamp} key={id} ref={ref}
      onAnimationEnd={(e) => { setDeletingMessage(message.timestamp) }}
    >
      <strong className="message_username">
        {message.message.alias || message.username}
        {message.message.alias ? ` (${message.username})` : message.message.alias}
      </strong>
      {message.username === myId ?
        <div className="message_options">
          <span className="edit_message"
            onClick={(e) => {
              e.preventDefault();
              editIndex.current = index;
              e.target.parentNode.parentNode.classList.add('editing');
              setEditMessage(message);
            }}>
            ✏️
          </span>
          &nbsp;|&nbsp;
          <span className="delete_message"
            onClick={() => {
              setDeleteMessage(message.timestamp)
            }}>
            ❌
          </span>
        </div>
        : null}
      <div className="message_time">
        {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </div>
      <div className="message_content">
        {parse(message.message.text)}
      </div>
      {message.message.document !== undefined ?
        ['image', 'document'].map(
          file_type => message.message.document.map((document, index) =>
            <MessageDocumentComponent
              attachment={document}
              key={id + index}
              uuid={props.myId}
              id={id}
              file_type={file_type}
              index={index}
              passcode={props.passcode}
              signed={props.signed}
            />
          ))
        :
        null}
      {message.message.edited ? <div className="message_edited" draggable="false">edited</div> : null}
    </div>
  );
});

export default MessageLineComponent;