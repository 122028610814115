import { useEffect } from 'react';

export default function WindowEvents() {

    useEffect(() => {
        const dragEvent = (e) => {
            e.preventDefault();
            if (e.clientX === 0 && e.clientY === 0) {
                document.getElementById('dropzone').classList.remove('drophover');
            } else {
                document.getElementById('dropzone').classList.add('drophover');
            }
        }
        window.addEventListener('dragenter', dragEvent);
        window.addEventListener('dragleave', dragEvent);

        return () => {
            window.removeEventListener('dragenter', dragEvent);
            window.removeEventListener('dragleave', dragEvent);
        }
    }, []);
}