import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';

function QuillComponent(props) {
  const [value, setValue] = useState('');
  const placeholder = props.placeholder;
  const setIamActive = props.setIamActive;

  const onChange = value => {
    setValue(value);
    if (value === '<p><br></p>') {
      document.getElementById('message').value = '';
    } else {
      document.getElementById('message').value = value;
    }
    setIamActive(Date.now());
  }
  const toolbar = [
    ['link'],
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    ['blockquote', 'code-block']
  ];

  return <ReactQuill id="message_wysiwyg" ref={props.quillRef} theme="snow" value={value} onChange={onChange} placeholder={placeholder} modules={{ toolbar: toolbar}} />
}

export default QuillComponent;